@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "~ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500&display=swap);

#modal-root {
  position: relative;
  z-index: 999;
}

.ag-root-wrapper {
  border-radius: 4px;
}

.ag-theme-alpine .ag-header-cell-label {
  font-weight: bold;
  justify-content: center;
//  font-size: 30px;
}

.custom-grid-header .ag-header-cell {
  background-color: white;
}


.ag-theme-alpine {
  @include ag-theme-alpine((
          data-color:#2d2d2d,
          header-background-color:#f0f3f6,
          header-foreground-color:#2d2d2d,
          background-color:white,
          header-height: 36px,
          row-height:36px,
          //font-size : 0.75rem,
          borders:true,
          border-color:var(--gray-4),
    //cell-horizontal-border:solid #bec0c2,
    //header-column-separator: true,
    //header-column-separator-color:#bec0c2,
          header-text-style: normal
  ));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.ag-theme-alpine .ag-header-cell {
  padding-left: 0;
  padding-right: 0;
  justify-content : center;

}
.ag-theme-alpine .ag-header-select-all {
  margin-left: 6px;
}
.ag-theme-alpine .ag-cell-label-container {
  width: auto;
}

.ag-header-cell-text {
  overflow: visible;
  text-overflow: unset;
  white-space: pre-line;
  text-align: center;
  font-size: 0.75rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}

html {
  --gray-3: #8c8e90;
  --gray-4: #999;
  --green: #00ff19;
  --black: #2d2d2d;
  --blue-2: #4a90e2;
  --blue: #4191ff;
  --deep-blue: #1450a5;
  --sky: #85b8ff;
  --red: #eb5757;
  --gray-4: #bec0c2;
  --white: #ffffff;
  --gray-5: #dee1e2;
  --gray-6: #fafbfc;
  --gray-7: #f3f3f4;
  --pale-grey: #fafbfc;
}
